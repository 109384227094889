<template>
    <div class="col-sm-12">
        <CCol sm="12" lg="6">
            <div class="form-group form-row">
                <label class="col-sm-12 col-lg-auto mb-0 col-form-label-sm text-right">
                    {{ $t('label.dateRange') }}
                </label>
                <div class="col-sm-12 col-lg-auto d-flex justify-content-end">
                    <CSwitch
                        color="watch"
                        variant="3d"
                        type="checkbox"
                        :checked.sync="FgDate"
                        @update:checked="$emit('FgDate', $event)"
                    />
                </div>
            </div>
        </CCol>
        <div class="table-responsive col-sm-12 col-lg-12">
            <table class="table table-bordered text-center">
                <tbody>
                    <tr>
                        <th style="background: #e6e6e6;">{{ getName(VisitSummaryJson) }}</th>
                    </tr>
                    <tr>
                        <td>
                            <div class="d-flex justify-content-center flex-row">
                                <label style="font-size: 18px;" class="m-2"><b>{{getHour(VisitSummaryJson.HoursDs)}}</b> HRS</label> 
                                <h2>{{getPercentage(VisitSummaryJson.Percentage)}}%</h2>
                            </div>
                            <div v-if="FgDate">
                                <b style="font-size: 9px;" class="p-1">
                                    {{`${getDate(VisitSummaryJson.TransactionBegin)} ${getDateHour(VisitSummaryJson.TransactionBegin)} - ${getDate(VisitSummaryJson.TransactionFinish)} ${getDateHour(VisitSummaryJson.TransactionFinish)}`}}
                                </b>
                            </div>
                        </td>
                    </tr>
                    <tr v-if="VisitSummaryJson.VisitSummaryDetailJson&&VisitSummaryJson.VisitSummaryDetailJson.length!=0">
                        <td class="p-0">
                            <table class="time-in-port-table mb-0" style="height: 100%">
                                <tbody>
                                    <tr>
                                        <td class="p-0" v-for="Lv2 in VisitSummaryJson.VisitSummaryDetailJson" :key="Lv2.IdX"
                                            :style="`overflow: hidden; border-top: none; ${Lv2.FgBerth?'width: 52%; max-width: 52%;':'width: 12%; max-width: 12%;'}`">
                                            <div :style="`min-height: 35px; ${Lv2.FgBerth?'font-size: 12px; background: #749bd3':Lv2.FgOtherTime?'background: #5d5d5d; color: #fff;':'background: #e6e6e6'}`" class="p-1 d-flex align-items-center justify-content-center">
                                                <b>{{ getName(Lv2) }}</b>
                                            </div>
                                            <div>
                                                <label style="font-size: 10px;" class="p-1 col-sm-12 m-0"><b>{{getHour(Lv2.HoursDs)}}</b> HRS</label>
                                                <h5>{{getPercentage(Lv2.Percentage)}}%</h5>
                                            </div>
                                            <div v-if="FgDate">
                                                <label style="font-size: 9px;" class="p-1 col-sm-12 m-0"><b>{{`${getDate(Lv2.TransactionBegin)} ${getDateHour(Lv2.TransactionBegin)}`}}</b></label> 
                                                <label style="font-size: 9px;" class="p-1 col-sm-12 m-0"><b>{{`${getDate(Lv2.TransactionFinish)} ${getDateHour(Lv2.TransactionFinish)}`}}</b></label>
                                            </div>
                                            <div style="height: 100%" class="col-sm-12 px-0" v-if="Lv2.DetailJson&&Lv2.DetailJson.length!=0">
                                                <table class="time-in-port-table mb-0" style="height: 100%">
                                                    <tbody>
                                                        <tr>
                                                            <td class="p-0" v-for="Lv3 in Lv2.DetailJson" :key="Lv3.IdX">
                                                                <div :style="`min-height: 49px; ${Lv3.FgService? 'background: #52a3a6; color: #fff;':Lv3.FgOtherTime?'background: #5d5d5d; color: #fff;':'background: #f88e86;'}`" class="p-1 d-flex align-items-center justify-content-center">
                                                                    <b>{{ getName(Lv3) }}</b>
                                                                </div>
                                                                <div style="min-height: 121px;" class="d-flex align-items-center justify-content-center">
                                                                    <div>
                                                                        <div>
                                                                            <label style="font-size: 10px;" class="pt-1 col-sm-12 m-0"><b>{{getHour(Lv3.HoursDs)}}</b></label> 
                                                                            <label style="font-size: 8px;" class="p-0 col-sm-12 m-0">HRS</label>
                                                                            <h6>{{getPercentage(Lv3.Percentage)}}%</h6>
                                                                        </div>
                                                                        <div v-if="FgDate">
                                                                            <label style="font-size: 8px;" class="p-1 col-sm-12 m-0"><b>{{`${getDate(Lv3.TransactionBegin)}`}}</b></label> 
                                                                            <label style="font-size: 7px;" class="p-1 col-sm-12 m-0"><b>{{`${getDateHour(Lv3.TransactionBegin)}`}}</b></label>
                                                                            <label style="font-size: 8px;" class="p-1 col-sm-12 m-0"><b>{{`${getDate(Lv3.TransactionFinish)}`}}</b></label>
                                                                            <label style="font-size: 7px;" class="p-1 col-sm-12 m-0"><b>{{`${getDateHour(Lv3.TransactionFinish)}`}}</b></label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div style="height: 100%" class="col-sm-12 px-0" v-if="Lv3.DetailJson&&Lv3.DetailJson.length!=0">
                                                                    <table style="height: 100%" class="time-in-port-table mb-0">
                                                                        <tbody>
                                                                            <tr>
                                                                                <td class="p-0" v-for="Lv4 in Lv3.DetailJson" :key="Lv4.IdX">
                                                                                    <div :style="`min-height: 32px; font-size: 8px; ${Lv3.FgService? 'background: #52a3a6; color: #fff;' : 'background: #f7c0a3;'}`" class="p-1 d-flex align-items-center justify-content-center">
                                                                                        <b>{{ getName(Lv4) }}</b>
                                                                                    </div>
                                                                                    <div style="min-height: 121px;" class="d-flex align-items-center justify-content-center">
                                                                                        <div>
                                                                                            <div>
                                                                                                <label style="font-size: 10px;" class="pt-1 col-sm-12 m-0"><b>{{getHour(Lv4.HoursDs)}}</b></label> 
                                                                                                <label style="font-size: 8px;" class="p-0 col-sm-12 m-0">HRS</label>
                                                                                                <h6>{{getPercentage(Lv4.Percentage)}}%</h6>
                                                                                            </div>
                                                                                            <div v-if="FgDate">
                                                                                                <label style="font-size: 7px;" class="p-1 col-sm-12 m-0"><b>{{`${getDate(Lv4.TransactionBegin)}`}}</b></label> 
                                                                                                <label style="font-size: 7px;" class="p-1 col-sm-12 m-0"><b>{{`${getDateHour(Lv4.TransactionBegin)}`}}</b></label>
                                                                                                <label style="font-size: 7px;" class="p-1 col-sm-12 m-0"><b>{{`${getDate(Lv4.TransactionFinish)}`}}</b></label>
                                                                                                <label style="font-size: 7px;" class="p-1 col-sm-12 m-0"><b>{{`${getDateHour(Lv4.TransactionFinish)}`}}</b></label>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    
                                                                                    <div style="height: 100%" class="col-sm-12 px-0" v-if="Lv4.DetailJson&&Lv4.DetailJson.length!=0">
                                                                                        <table style="height: 100%" class="time-in-port-table mb-0">
                                                                                            <tbody>
                                                                                                <tr>
                                                                                                    <td class="p-0" v-for="Lv5 in Lv4.DetailJson" :key="Lv5.IdX">
                                                                                                        <div :style="`min-height: 32px; font-size: 8px; ${Lv3.FgService? 'background: #52a3a6; color: #fff;' : 'background: #749bd3;'}`" class="p-1 d-flex align-items-center justify-content-center">
                                                                                                            <b>{{ getName(Lv5) }}</b>
                                                                                                        </div>
                                                                                                        <div>
                                                                                                            <label style="font-size: 10px;" class="pt-1 col-sm-12 m-0"><b>{{getHour(Lv5.HoursDs)}}</b></label> 
                                                                                                            <label style="font-size: 8px;" class="p-0 col-sm-12 m-0">HRS</label>
                                                                                                            <h6>{{getPercentage(Lv5.Percentage)}}%</h6>
                                                                                                        </div>
                                                                                                        <div v-if="!Lv5.FgDelay&&FgDate">
                                                                                                            <label style="font-size: 7px;" class="p-1 col-sm-12 m-0"><b>{{`${getDate(Lv5.TransactionBegin)}`}}</b></label> 
                                                                                                            <label style="font-size: 7px;" class="p-1 col-sm-12 m-0"><b>{{`${getDateHour(Lv5.TransactionBegin)}`}}</b></label>
                                                                                                            <label style="font-size: 7px;" class="p-1 col-sm-12 m-0"><b>{{`${getDate(Lv5.TransactionFinish)}`}}</b></label>
                                                                                                            <label style="font-size: 7px;" class="p-1 col-sm-12 m-0"><b>{{`${getDateHour(Lv5.TransactionFinish)}`}}</b></label>
                                                                                                        </div>
                                                                                                    </td>
                                                                                                </tr>
                                                                                            </tbody>
                                                                                        </table>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex';
import { DateFormater } from '@/_helpers/funciones';

function data() {
    return {
        FgDate: true,
    }
}
//methods
function getName(item) {
    let _lang = this.$i18n.locale;
    return _lang == 'en' ? (item.NameEn || '') : (item.NameEs || '');
}

function getHour(Hour) {
    return Hour ?? '00:00';
}

function getPercentage(Percentage) {
    return Percentage ?? 0;
}

function getDate(date) {
    return date ? DateFormater.formatDateTimeWithSlashToOnlyDate(DateFormater.formatDateTimeWithSlash(date)) : '-';
  }

function getDateHour(date) {
    return date ? DateFormater.formatDateTimeWithSlashToOnlyTime(DateFormater.formatDateTimeWithSlash(date)) : '';
}
//computed

export default {
    name:"time-in-port",
    data,
    props: {
        VisitSummaryJson:{
            type: Object,
            default: function () {
                return {} 
            },
        },
    },
    mixins: [],
    methods: {
        getName,
        getHour,
        getPercentage,
        getDate,
        getDateHour,
    },
    computed:{
        ...mapState({
            collapseManagement : state=> state.Management.collapseManagement,
            CarruselTab: state => state.Management.CarruselTab,
        })
    },
    watch:{
        collapseManagement: function (NewVal) {
            if (NewVal) {
                this.FgDate = true;
            }
        }
    }
}
</script>
<style scoped>
  .time-in-port-table{
    width: 100%;
    border-collapse: collapse;
    font-size: 9px;
  }
  table.time-in-port-table tr:last-child td {
    border-bottom: none;
  }
  table.time-in-port-table th{
    border-top: none !important;
    border-left: none !important;
    border-bottom: none;
  }
  table.time-in-port-table th:last-child {
    border-right: none !important;
  }
  table.time-in-port-table td:first-child {
    border-left: none !important;
    border-right: none !important;
  }
  table.time-in-port-table td:last-child {
    border-right: none !important;
  }
</style>